$mobile-modern: 375px;
$mobile-landscape: 480px;
$large-mobile: 620px;
$tablet: 768px;
$tablet-large: 960px;
$desktop: 1200px;
$desktop-large: 1360px;
$wide-screen: 1400px;

@mixin wider($width) {
  @media (min-width: #{$width}) {
    @content;
  }
}

@mixin narrower($width) {
  @media (max-width: #{$width}) {
    @content;
  }
}

@mixin show($width) {
  display: none;

  @include wider($width) {
    display: block;
  }
}

@mixin hide($width) {
  display: block;

  @include wider($width) {
    display: none;
  }
}
