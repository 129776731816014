@import "../styles/colours";

.copy {
  display: inline-flex;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.copy-text {
  color: $elephant;
  margin-left: 0.5rem;
}
