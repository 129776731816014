@import "../../styles/colours.scss";

.logo {
  height: 2rem;

  svg {
    margin: 0 1rem;

    path {
      fill: $black;
    }
  }
}
