@import "styles/colours.scss";
@import "styles/variables.scss";
@import "styles/mixins.scss";



.container {
  border-radius: 1rem;
  border: 1px dashed $elephant;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.25rem 0 1rem 0;
  cursor: pointer;
  min-width: 20rem;
  text-align: center;
  padding: 1rem;
  height: 15rem;

  &.active {
    @include boxShadow(0, 0.2rem, 0.3rem, $black);
    transform: translateY(-2px);
  }
  
  &.reject {
    border-color: $red;
  }

  &:hover {
    border-color: $elephant;
    @include boxShadow(0, 0.2rem, 0.5rem);
    transform: translateY(-1px);
  }

  .with-line {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $elephant;
    line-height: 0.1em;
    margin: 2rem 0;
  }

  .or {
    background: $white;
    padding: 0 1rem;
    text-transform: uppercase;
  }
}
