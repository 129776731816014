@import "../../../styles/mixins.scss";
@import "../../../styles/colours";
@import "../../../styles/responsive";

.account-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;

  align-items: center;
  height: 100%;
  justify-content: center;
}

.preset-title {
  margin: 0;
}

.account-title {
  margin-top: 0;
}

.separator {
  border-top: 1px solid $elephant;
  width: 50%;
  margin: 2rem 0;

  @include narrower($tablet) {
    width: 100%;
  }
}
