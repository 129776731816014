@import "../../../styles/colours";

.error {
  color: $red;
  margin: 0.5rem 0;
  font-size: 1rem;
}

.heading {
  font-size: 1rem;
  font-family: "Moderat-Light", sans-serif;
  margin: 0;
  width: 100%;
}

.input {
  margin-right: 1.5rem;
}

.value {
  margin: 0;
  padding: 0;
}

.actions {
  display: flex;

  button {
    margin-right: 1rem;
  }

  button:last-of-type {
    padding: 0;
    margin-right: 0;
    margin-left: auto;
  }
}


.container {
  display: flex;
  justify-content: space-between;
}
