@import "./colours";
@import "./responsive";
@import "./variables";

$font-sizes: (
  h1: 2.5rem,
  h3: 1.5rem,
  small: 1rem
);

@mixin font-size($font-tag) {
  @each $tag, $size in $font-sizes {
    @if $font-tag == $tag {
      font-size: $size;
    }
  }
}

@font-face {
  font-family: $font-paragraph-regular;
  src: local($font-paragraph-regular),
    url("../assets/fonts/Moderat-Regular.woff2") format("woff2"),
    url("../assets/fonts/Moderat-Regular.woff") format("woff");
}

@font-face {
  font-family: $font-paragraph-light;
  src: local($font-paragraph-light),
    url("../assets/fonts/Moderat-Light.woff2") format("woff2"),
    url("../assets/fonts/Moderat-Light.woff") format("woff");
}

@font-face {
  font-family: $font-headers-bold;
  src: local($font-headers-bold),
    url("../assets/fonts/Stuart-Titling-Bold-205TF.woff2") format("woff2"),
    url("../assets/fonts/Stuart-Titling-Bold-205TF.woff") format("woff");
}

@font-face {
  font-family: $font-headers-regular;
  src: local($font-headers-regular),
    url("../assets/fonts/Stuart-Titling-Regular-205TF.woff2") format("woff2"),
    url("../assets/fonts/Stuart-Titling-Regular-205TF.woff") format("woff");
}

html {
  font-size: 80%;

  @include wider($mobile-modern) {
    font-size: 85%;
  }

  @include wider($mobile-landscape) {
    font-size: 90%;
  }

  @include wider($tablet) {
    font-size: 95%;
  }

  @include wider($desktop) {
    font-size: 100%;
  }
}

body {
  color: $black;
  font-family: "Moderat-Light", sans-serif;
  font-size: 1.125rem;
  line-height: 1.25;
}

@each $tag, $size in $font-sizes {
  #{$tag} {
    font-size: $size;
  }
}

a {
  color: $accent2;
  text-decoration: none;
}

h1,
h2,
h3 {
  font-family: "Stuart-Bold", serif;
}
