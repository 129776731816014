@import "../../../styles/colours";

.List {
  padding: 2rem 1.25rem 4rem;
  overflow: auto;

  .cell {
    border-right: 1px solid $elephant;
    border-bottom: 1px solid $elephant;
    padding: 0;
    &:first-child {
      border-left: 1px solid $elephant;
      white-space: nowrap;
    }
    &:last-child {
      text-align: right;
      padding: 0.75rem 1.25rem;
    }
  }
  .heading {
    border-right: 1px solid $elephant;
    border-bottom: 1px solid $elephant;
    border-top: 1px solid $elephant;
    padding: 0.75rem 1.25rem;
    text-align: left;
    text-transform: uppercase;
    background: $lightElephant;
    &:first-child {
      border-left: 1px solid $elephant;
      border-radius: 10px 0 0 0;
    }
    &:last-child {
      text-align: right;
      border-radius: 0 10px 0 0;
      min-width: 14rem;
    }
  }
  .link {
    color: $black;
    display: block;
    padding: 0.75rem 1.25rem;
  }
  .row {
    background: $white;
    transition: background-color 100ms ease-in-out;
    &:hover {
      background: $lightElephant;
    }
  }
  .table {
    border-spacing: 0;
    margin: 0 auto;
  }
}

.SpinnerWrapper {
  position: relative;
  top: -2rem;
}

:global {
  .completed,
  .confirmed,
  .declined,
  .paid,
  .started,
  .requested {
    > a {
      color: $white;
    }
  }
  .completed {
    background: $green;
  }
  .confirmed {
    background: $accent2;
  }
  .declined {
    background: $red;
  }
  .paid {
    background: $accent7;
  }
  .requested {
    background: $accent8;
  }
  .started {
    background: $orange;
  }
}
