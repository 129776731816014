@import "../styles/colours";

.button {
  align-items: center;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0.25rem 0.5rem 0 rgba($black, 0.3);
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  transition: all 0.3s ease-in;
  font-family: Moderat-Regular, sans-serif;
  font-size: 1rem;

  &:hover,
  &:focus {
    box-shadow: none;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $elephant;
    border-color: $elephant;
    cursor: default;
    pointer-events: none;
  }

  svg {
    fill: $black;
    margin-left: 0.75rem;
    stroke: $black;
    width: 1rem;
  }

  &__primary {
    background-color: $white;
    border-color: $black;
    border-radius: 0.25rem;
    text-transform: uppercase;
  }

  &__secondary {
    background: transparent;
    border-color: $black;
    border-radius: 0.25rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba($black, 0.5);
    color: $black;
    text-transform: uppercase;
  }

  &__danger {
    background-color: $red;
    border-color: $red;
    border-radius: 0.25rem;
    color: $white;
    text-transform: uppercase;
  }

  &__solid {
    background-color: $black;
    border-color: $black;
    border-radius: 0.25rem;
    color: $white;
    text-transform: uppercase;
  }

  &__link {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    color: $accent2;
    text-transform: none;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.125rem;
    margin: 0;
    box-shadow: none;
  }
}
