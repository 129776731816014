@import "../../styles/mixins.scss";
@import "../../styles/colours.scss";

.spinner {
  $size: 16px;
  z-index: -1;

  &:after {
    margin: 1rem;
    display: block;
    content: " ";
    border: 2px solid $black;
    border-left-color: fade-out($black, 0.5);
    border-radius: $size;
    width: $size;
    height: $size;
    animation: spinner-anim 500ms infinite linear;
  }

  &--cover {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--light {
    border: 2px solid $white;
    border-left-color: fade-out($white, 0.5);
  }

  &--centered {
    @include fill(fixed);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    top: 5rem;
  }
}

@-webkit-keyframes spinner-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
