.header {
  user-select: none;
  margin-bottom: 1rem;

  &::before {
    display: inline-block;
    text-align: center;
    content: "⏷";
    margin-right: 0.7rem;
  }
}

.headerHidden::before {
  content: "⏵";
}

.hidden {
  display: none;
}
