@import "../../styles/mixins.scss";

.tabs {
  align-self: flex-start;
  padding-top: 2rem;
  button {
    background-color: transparent;
    border: none;
  }

  .tab {
    cursor: pointer;
    color: $black;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
  }

  .active .tab:after {
    background-color: $black;
    display: block;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    top: 1.65rem;
    width: 100%;
  }
}
