@import "../../../../styles/colours";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.property-details {
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  margin: 0.25rem 0;

  @include wider($tablet-large) {
    flex-wrap: wrap;
    align-items: center;
  }

  svg {
    fill: $black;
    height: 1rem;
    margin-right: 0.75rem;
    width: 1rem;
  }

  &__more {
    @include wider($mobile-modern) {
      margin-left: 1.75rem;
    }

    button {
      @include font-size(small);

      align-items: center;
      color: $accent2;
      border: none;
      display: flex;
      padding: 0;
      text-align: left;

      &:hover,
      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    p {
      margin: 0.75rem 0;
    }
  }
}
