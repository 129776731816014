@import "../../../styles/mixins.scss";
@import "../../../styles/colours";
@import "../../../styles/responsive";

.account-container {
  margin-top: 2rem;
  padding: 0 1rem 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  box-shadow: 0 0.1rem 0.3rem;
  border-radius: 0.5rem;
}
