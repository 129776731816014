@import "../../../styles/colours";

.error {
  color: $red;
  margin: 0.5rem 0;
  font-size: 1rem;
}

.heading {
  font-size: 1rem;
  font-family: "Moderat-Light", sans-serif;
  margin: 0;
  width: 100%;
}

.input {
  margin-right: 1.5rem;
}

.value {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: space-between;

  &__inplace {
    justify-content: flex-end;

    // In inplace mode edit button has the value as text
    .value,
    [type="submit"] {
      display: none;
    }

    input {
      border: 0;
      text-align: right;
      padding: 0;
      font-size: 1.125rem;
      margin: 0;
      line-height: 1;
      display: block;
      background: 0;
    }

    .empty-value {
      color: $accent2;
      text-decoration: underline;
    }
  }
}
