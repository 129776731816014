@import "../../../../styles/mixins.scss";
@import "../../../../styles/responsive";

.show-preset {
  display: flex;

  height: 100%;
  justify-content: center;

  @include narrower($tablet) {
    flex-direction: column;
  }
}
