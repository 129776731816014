@import "../../../styles/colours";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin: 2rem auto;
  max-width: 1200px;

  div:empty {
    display: none;
  }
}

.clean-header {
  display: flex;
  flex-flow: row-reverse;
  margin-top: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
}

.header {
  font-size: 1.4rem;
  margin-top: 0;
}

.label {
  font-size: 1rem;
  font-family: "Moderat-Light", sans-serif;
  margin: 0;
}

.tab {
  border: 2px solid $elephant;
  border-radius: 15px;
  padding: 1rem;
}

.value {
  margin: 0;
}

.editable-value {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
  border-top: 1px solid $lightElephant;
  margin-top: 1rem;
  padding-top: 1rem;

  &:last-of-type {
    border-bottom: 1px solid $lightElephant;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}
