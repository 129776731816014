@import "./colours";
@import "./mixins";

@mixin input-style() {
  @include boxShadow($opacity: 0.1);
  @include transitionAll;

  background: $white;
  border-radius: 5px;
  border: 1px solid $dark-elephant;
  box-sizing: border-box;
  font-family: $font-paragraph-regular, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  padding: 0.75rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  line-height: 1rem;
  width: 100%;
}

input[type="submit"] {
  background-color: $black;
  border: none;
  color: $white;
  transition: all 0.3s ease-in;

  &:hover {
    background-color: darken($black, 10%);
    cursor: pointer;
  }
}

input:not([type="radio"]),
select,
.form__input {
  max-height: 50px;

  @include wider($desktop-large) {
    max-height: 60px;
  }
}

input:not([type="radio"]):not([type="checkbox"]),
select,
textarea,
.form__input {
  @include input-style();

  &[disabled],
  &[readonly="readonly"] {
    @include disabled-input;
  }

  &:focus {
    @include boxShadow();

    border-color: $black;
    outline: none;
  }
}

input[type="checkbox"] {
  margin-right: 1em;
  white-space: nowrap;
  width: auto;
  height: auto;

  label {
    display: block;
    padding: 0.5em 0;
  }
}

select {
  padding: 0.75rem 1.5rem 0.7rem 0.75rem;
  line-height: initial;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: none;
  }
}

.form {
  width: 16rem;

  button {
    width: 100%;
  }
}

.field {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  position: relative;

  &__error {
    display: block;
    color: $red;
    text-align: left;
  }

  .DefaultError {
    text-align: left;
  }

  [type="submit"] {
    margin-top: 2rem;
  }
}

.react-datepicker {
  font-family: inherit;
  .react-datepicker {
    &__day--in-range,
    &__month-text--in-range,
    &__quarter-text--in-range,
    &__year-text--in-range {
      background-color: lighten($elephant, 15);
      color: $black;

      &:hover {
        background-color: darken($elephant, 35);
      }
    }

    &__day--in-selecting-range,
    &__month-text--in-selecting-range,
    &__quarter-text--in-selecting-range,
    &__year-text--in-selecting-range {
      background-color: lighten($elephant, 15);
      color: $black;

      &:hover {
        background-color: darken($elephant, 35);
        color: $white;
      }
    }

    &__day--selected,
    &__month-text--selected,
    &__quarter-text--selected,
    &__year-text--selected {
      background-color: darken($elephant, 35);
      color: $white;
    }
  }
}
