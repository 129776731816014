@import "../../../styles/colours.scss";
@import "../../../styles/responsive.scss";

.header {
  background-color: $yellow;
  padding: 2rem 0 0;
}

.details {
  align-items: center;
  display: flex;
  color: $black;
  justify-content: space-between;

  &__title {
    margin: 0 0 0.5rem;
  }

  &__description {
    margin: 0;
  }

  &__actions {
    display: flex;

    button:first-of-type {
      margin-right: 1.25rem;
    }
  }
}

.tabs {
  margin-top: 2rem;

  .tab {
    cursor: pointer;
    color: $black;
    display: inline-block;
    margin-right: 2rem;
    padding-bottom: 0.5rem;
    position: relative;
  }

  .active .tab:after {
    background-color: $black;
    display: block;
    content: "";
    height: 0.25rem;
    left: 0;
    position: absolute;
    top: 1.65rem;
    width: 100%;
  }
}

.container {
  margin: 0 auto;
  max-width: $desktop;
  padding: 0 1.25rem;
}
