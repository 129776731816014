.modal-container {
  form {
    .fields {
      max-width: 50%;
      margin: auto;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
