.chart {
  padding-top: 1.25rem;
}

.loading,
.error {
  margin: 2rem auto;

  p {
    text-align: center;
  }
}
