@import "../../../styles/colours.scss";

.pack {
  align-items: center;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  margin: 0.25rem;
  font-size: 16px;

  &__count,
  &__type {
    padding: 0.5rem;
  }

  &__type {
    padding: 0.5rem;
    color: white;
  }

  &__count {
    border-right: 1px solid $black;
  }

  &__type {
    background-color: $black;
    color: $white;
    text-align: center;
    min-width: 6rem;
    width: 100%;

    &--single {
      background-color: #2196f3;
    }

    &--small_single {
      background-color: #00bcd4;
    }

    &--super_king {
      background-color: #009688;
    }

    &--king {
      background-color: #4caf50;
    }

    &--double {
      background-color: #ff5722;
    }

    &--small_double {
      background-color: #e91e63;
    }

    &--queen {
      background-color: #464672;
    }
  }
}
