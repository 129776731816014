@import "../../../../styles/mixins";
@import "../../../../styles/colours";
@import "../../../../styles/responsive";

$row-spacing: 0.75rem;

.day {
  display: flex;

  border-top: 1px solid $elephant;
  transition: all 0.3s ease-in;

  &--open {
    background-color: lighten($lightElephant, 6%);

    .day__arrow svg {
      transform: rotate(180deg);
    }
  }

  &:hover {
    background-color: lighten($lightElephant, 3%);
    cursor: pointer;
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $row-spacing;
    border-right: 1px solid $elephant;
    width: 25%;

    @include wider($mobile-landscape) {
      width: 15%;
    }
  }

  &__arrow {
    margin-left: 0.5rem;

    svg {
      fill: $black;
      transition: all 0.3s ease-in;
      width: 1rem;
    }
  }

  &__beds {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: $row-spacing;
    border-right: 1px solid $elephant;
    width: 50%;

    @include wider($mobile-landscape) {
      width: 70%;
    }
  }

  &__count {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $row-spacing;
    width: 25%;

    @include wider($mobile-landscape) {
      width: 15%;
    }

    p {
      margin: 0;
    }
  }
}
