@import "../../../styles/mixins";
@import "../../../styles/colours";

.main {
  padding-bottom: 5rem;
}

.actions {
  display: flex;

  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem 0;

  p {
    margin: 0 1.25rem 0;
  }

  &__download {
    margin-left: 1.25rem;
  }

  &__datepicker {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    :global .react-datepicker-wrapper {
      margin-left: 1.25rem;
      input {
        padding: 0.5rem 0.75rem;
      }
    }
  }

  &__decoration {
    height: 2px;
    width: 2rem;
    background: black;
    margin-left: 1.25rem;
    border-radius: 1px;
  }
}
