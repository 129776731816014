@import "../../../../styles/mixins";
@import "../../../../styles/colours";

.order-list {
  background-color: $white;
  border: 2px solid $elephant;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.75rem 1.25rem 0 rgba($black, 0.3);
}

.order-list > div:first-child {
  border-top: none;
}

.order-list > div:last-child {
  border-bottom: none;
}

.empty {
  text-align: center;
}
