$font-headers-regular: "Stuart-Regular";
$font-headers-bold: "Stuart-Bold";
$font-paragraph-regular: "Moderat-Regular";
$font-paragraph-light: "Moderat-Light";

$small-size: 0.83rem;
$regular-size: 1rem;
$header-size: 1.4rem;
$big-header-size: 1.75rem;
$small-header-size: 1.2rem;
