@import "./colours.scss";

@mixin fill($position: fixed) {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: $position;
}

@mixin boxShadow(
  $xOffset: 0,
  $yOffset: 0.1rem,
  $blur: 0.3rem,
  $color: $elephant,
  $opacity: 0.2
) {
  box-shadow: $xOffset $yOffset $blur rgba($color, $opacity);
}

@mixin borderRadius($size: 0.25rem) {
  border-radius: $size;
}

@mixin transitionAll($time: 0.3s, $easing: ease-in) {
  transition: all $time $easing;
}

@mixin disabled-input {
  background-color: $elephant;
  opacity: 0.3;
  cursor: not-allowed;
}
