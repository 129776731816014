@import "../../../../styles/mixins.scss";
@import "../../../../styles/colours";
@import "../../../../styles/responsive";

.order {
  display: flex;
  flex-direction: column;

  @include wider($tablet) {
    flex-direction: row;
  }

  align-items: center;
  border: 1px solid $elephant;
  border-left: 0.25rem $black;
  justify-content: space-between;
  margin: -1px;
  padding: 0.5rem;

  &--failed {
    background-color: lighten($red, 30%);
    border-left-color: $red;
  }

  &__collection {
    background: $lightElephant;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid $black;
    text-transform: uppercase;
  }

  &__details {
    width: 100%;
    margin-bottom: 1rem;

    @include wider($tablet) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__detail {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0.25rem 0;

    svg {
      fill: $black;
      height: 1rem;
      margin-right: 0.75rem;
      width: 1rem;
    }
  }

  &__packs {
    width: 100%;

    @include wider($tablet) {
      width: 50%;
    }
  }

  &__packs-list {
    display: flex;
    flex-wrap: wrap;

    @include wider($tablet) {
      justify-content: flex-end;
    }
  }

  &__notes {
    color: $black;
    margin: 0 0 0.5rem;
    text-align: right;
  }

  &__extra {
    display: block;
    color: $elephant;
    margin-top: 0.5rem;

    @include wider($tablet) {
      text-align: right;
    }

    strong {
      color: $black;
    }
  }

  .cleanstatus {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 0.75rem;
      width: 1rem;
    }

    p {
      margin: 0;
    }

    &--scheduled {
      color: $orange;
    }

    &--started {
      color: $green;
    }

    &--completed {
      color: $red;
    }
  }
}
