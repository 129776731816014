$white: #ffffff;
$black: #000000;
$yellow: #f5f514;

$red: #b30e0e;
$orange: #f9c310;
$green: #239a5e;

$lightElephant: #e6e6e6;
$elephant: #b3b3b3;
$dark-elephant: #373737;


$accent1: #4e297d;
$accent3: #9d53fd;
$accent5: #f1e6ff;

$accent2: #078fd9;
$accent4: #cedcff;
$accent6: #f8f8f8;

$accent7: #1e6b44;
$accent8: #005079;
