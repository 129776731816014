@import "~normalize-scss/sass/normalize";
@import "~react-datepicker/src/stylesheets/datepicker";
@include normalize();
@import "./typography";
@import "./forms";
@import "./mixins";

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

.fixed-centered {
  @include fill(fixed);
  display: flex;
  flex-direction: column;

  align-items: center;
  height: 100%;
  top: 5rem;
}

.dont-print {
  @media print {
    display: none !important;
  }
}

.hidden {
  display: none !important;
}

.App {
  padding-bottom: 3rem;
}