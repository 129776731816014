@import "../../../styles/mixins.scss";

.CleanImages {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0 0.25rem;
  flex-wrap: wrap;
  gap: 5px;
  line-height: 0;
  margin-bottom: 1rem;
}

.header {
  font-size: 1.4rem;
  margin-top: 0;
}

.CleanImage {
  cursor: pointer;
  position: relative;

  &:after {
    content: "\a";
    white-space: pre;
  }

  img {
    height: 100px;
    width: 100px;
    @include borderRadius;
  }
}
