@import "../../../styles/colours";

.Tasks {
  list-style-type: none;
  padding: 0;

  &__item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 2rem;
    &:before {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      border: 1px solid $black;
      border-radius: 2px;
      position: absolute;
      top: 2px;
      left: 0;
    }

    > a {
      @media print {
        text-decoration: underline;
      }
    }
  }
}
