.card {
  box-shadow: 0 0.1rem 0.3rem;
  min-width: 29rem;
  max-width: 40vw;
  border-radius: 0.5rem;
  height: fit-content;

  input {
    width: 100%;
  }

  &-top {
    display: flex;
    justify-content: center;
    padding: 2.2rem 2rem 2rem 2rem;
    position: relative;
    margin: unset;
    flex-direction: column;
    align-items: center;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      bottom: 0;
      background: #b0b0b0;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  &-body {
    padding: 2rem 2rem 0;
  }

  &-actions {
    padding: 2rem 0 1rem 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      padding: 0.75rem;
      margin-bottom: 1rem;
    }
  }

  &-header {
    margin-bottom: 0;
  }
}
